<template>
  <v-badge
    v-if="count"
    color="success"
    :content="count"
    overlap
  >
    <v-btn
      @click="$store.dispatch('sections/select', {section, view: 'notes'})"
      color="grey lighten-2" 
      fab x-small depressed
    >
      <v-icon color="primary" style="opacity: 0.3" small>mdi-book-open-outline</v-icon>
    </v-btn>
  </v-badge>
</template>

<script>
export default {
  name: 'NotesFloat',

  /*------------------------------------------------------------------------------
   * DATA
   *----------------------------------------------------------------------------*/
  data() {
    return {
      count: 0
    }
  },

  /*------------------------------------------------------------------------------
   * PROPS
   *----------------------------------------------------------------------------*/
  props: {
    section: Object
  },

  /*------------------------------------------------------------------------------
   * MOUNTED
   *----------------------------------------------------------------------------*/
  mounted() {
    this.section.ref.collection('notes')
    .get()
    .then(snapshot => {
      this.count = snapshot.size
    })
  }
}
</script>