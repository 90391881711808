<template>
  <div>
    <v-text-field
      v-model="search"
      placeholder="Search"
      append-icon="mdi-magnify"
      hide-details
      class="mb-3"
      clearable
      outlined
      dense
    ></v-text-field>

    <v-fade-transition group>
      <a 
        v-for="template in refined"
        :key="template.id"
        :class="{ 'grey lighten-2' : selected && template.id == selected.id }"
        @click="selectTemplate(template)"
        class="body-sm d-block pa-2"
        :style="{ opacity: template.visible ? '1' : '0.9' }"
      >
        {{ template.name }} <em v-if="!template.categories">(no category)</em>
      </a>
    </v-fade-transition>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { intersection, orderBy } from 'lodash'

export default {
  props: {
    categories: Array,
    showVisiblesOnly: Boolean,
  },

  /*------------------------------------------------------------------------------
   * DATA
   *----------------------------------------------------------------------------*/
  data() {
    return {
      search: null,
      selected: null,
    }
  },

  /*------------------------------------------------------------------------------
   * COMPUTED
   *----------------------------------------------------------------------------*/
  computed: {
    ...mapState({
      templates: (state) => state.pageTemplates.templates,
      status: (state) => state.pageTemplates.status,
    }),

    refined: function () {
      let refined = this.templates

      if (this.search) {
        refined = refined.filter((template) => {
          return template.name.toLowerCase().includes(this.search.toLowerCase())
        })
      }

      refined = refined.filter((template) => {
        if (template.categories) {
          return intersection(template.categories, this.categories).length || !template.categories.length
        }
        else if (!template.categories) {
          return true
        }
      })

      if (this.showVisiblesOnly) {
        refined = refined.filter((template) => {
          return template.visible
        })
      }

      refined = orderBy(refined, 'name', 'asc')

      return refined
    }
  },

  /*------------------------------------------------------------------------------
   * METHODS
   *----------------------------------------------------------------------------*/
  methods: {
    ...mapActions('pageTemplates', [
      'getTemplates'
    ]),

    selectTemplate(template) {
      this.selected = template
      this.$emit('selected', template)
    },
  },

  /*------------------------------------------------------------------------------
   * MOUNTED
   *----------------------------------------------------------------------------*/
  mounted() {
    if (!this.status.firstLoad) this.getTemplates()
  }
}
</script>