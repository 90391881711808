<template>
  <v-hover>
      <template v-slot:default="{ hover }">
        <v-card tile flat>
          <div v-show="!$store.state.preview" class="page-floating-icons">
            <notes-float :section="section" />
          </div>

          <v-fade-transition>
            <BlockImg
              :style="
                $store.state.sections.selected && 
                $store.state.sections.selected.id == section.id ? 'z-index: 7' : ''
              " 
              @popup="showPopup()"
              :data="data"
              :type="type"
              v-if="file"
            />
          </v-fade-transition>

          <v-fade-transition v-if="!nonEditable">
            <v-overlay
              v-if="hover && !$store.state.preview"
              class="section-overlay-body"
              z-index="2"
              absolute
            >
              <!-- PREPEND BLOCK BUTTON -->
              <v-card v-if="section.type == 'body'" class="d-flex gap-7 pa-1" color="accent" rounded="pill">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn 
                      @click="prependSection(section)"
                      color="black"
                      v-on="on"
                      x-small
                      top
                      fab
                    >
                      <v-icon small>mdi-plus-thick</v-icon>
                    </v-btn>
                  </template>
                  <span class="caption">Add A Panel Above</span>
                </v-tooltip>
              </v-card>
              
              <!-- COLOR AND ORIENTATION BUTTONS -->
              <div class="align-self-start" :style="{ position: 'absolute', left: '10px', bottom: '10px' }">
                <color-selector v-if="section" :section="section" />
              </div>

              <!-- ACTION BUTTONS -->
              <v-card class="d-flex gap-7 pa-1" color="accent" rounded="pill">
                <!-- REPLACE BLOCK TEMPLATE -->
                <v-tooltip
                  v-if="
                    (section.type == 'header' && $store.state.page.selected && $store.state.page.selected.header !== 'custom') 
                    || (section.type == 'footer' && $store.state.page.selected && $store.state.page.selected.footer !== 'custom') 
                    || section.type == 'body'"
                  bottom
                >
                  <template v-slot:activator="{ on }">
                    <v-btn
                      @click="$emit('replace', section)"
                      color="black"
                      v-on="on"
                      depressed
                      x-small
                      fab
                    >
                      <v-icon small>mdi-file-replace-outline</v-icon>
                    </v-btn>
                  </template>
                  <span class="caption">Switch Panel</span>
                </v-tooltip>

                <!-- DUPLICATE SECTOIN -->
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      @click="duplicateSection(section)"
                      v-if="section.type == 'body'"
                      color="black"
                      v-on="on"
                      depressed
                      x-small
                      fab
                    >
                      <v-icon small>mdi-content-copy</v-icon>
                    </v-btn>
                  </template>
                  <span class="caption">Duplicate Section</span>
                </v-tooltip>
                
                <template v-if="$store.state.user.user && !isPageTemplate && !project.template">
                  <!-- PROJECT NOTES -->
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        @click="$store.dispatch('sections/select', {section, view: 'notes'})"
                        color="black"
                        v-on="on"
                        depressed
                        x-small
                        fab
                      >
                        <v-icon small>mdi-book-open-outline</v-icon>
                      </v-btn>
                    </template>
                    <span class="caption">Notes</span>
                  </v-tooltip>
                  
                  <!-- FILES -->
                  <v-tooltip v-if="$store.state.user.user && ($store.getters['user/isAdmin'] || $store.getters['user/isEmployee'])" bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        @click="$store.dispatch('sections/select', {section, view: 'attachments'})"
                        color="black"
                        v-on="on"
                        depressed
                        x-small
                        fab
                      >
                        <v-icon small>mdi-paperclip</v-icon>
                      </v-btn>
                    </template>
                    <span class="caption">Attachments</span>
                  </v-tooltip>

                  <!-- SECTION COPY -->
                  <v-tooltip v-if="$store.state.user.user && ($store.getters['user/isAdmin'] || $store.getters['user/isEmployee'] || $store.getters['user/isClient'])" bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        @click="$store.dispatch('sections/select', {section, view: 'copy'})"
                        color="black"
                        v-on="on"
                        depressed
                        x-small
                        fab
                      >
                        <v-icon small>mdi-file-document-outline</v-icon>
                      </v-btn>
                    </template>
                    <span class="caption">Content Copy</span>
                  </v-tooltip>
                </template>
                
                <!-- DELETE -->
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      @click="$emit('delete', section)"
                      color="black"
                      v-on="on"
                      depressed
                      x-small
                      fab
                    >
                      <v-icon color="red" small>mdi-trash-can-outline</v-icon>
                    </v-btn>
                  </template>
                  <span class="caption">Delete</span>
                </v-tooltip>
                
              </v-card>

              <!-- APPEND BLOCK BUTTON -->
              <v-card  v-if="section.type == 'body'" class="d-flex gap-7 pa-1" color="accent" rounded="pill">
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn 
                      @click="appendSection(section)"
                      color="black"
                      v-on="on"
                      x-small
                      top
                      fab
                    >
                      <v-icon small>mdi-plus-thick</v-icon>
                    </v-btn>
                  </template>
                  <span class="caption">Add A Panel Below</span>
                </v-tooltip>
              </v-card>
            </v-overlay>
          </v-fade-transition>
        </v-card>
      </template>
    </v-hover>
</template>

<script>
import firebase from 'firebase'
import _camelCase from 'lodash/camelCase'
import { mapState, mapActions } from 'vuex'

import NotesFloat from '@/components/NotesFloat'
import ColorSelector from '@/views/project/ColorSelector'

export default {
  name: 'PageSection',

  /*------------------------------------------------------------------------------
   * PROPS
   *----------------------------------------------------------------------------*/
  props: {
    section: Object,
    nonEditable: Boolean,
  },

  /*------------------------------------------------------------------------------
   * DATA
   *----------------------------------------------------------------------------*/
  data() {
    return {
      file: null,
      jpeg: null,
      type: null,
      data: null,
    }
  },

  /*------------------------------------------------------------------------------
   * COMPUTED
   *----------------------------------------------------------------------------*/
  computed: {
    ...mapState({
      project: (state) => state.project.project,
      isPageTemplate: (state) => state.page.isPageTemplate,
    })
  },

  /*------------------------------------------------------------------------------
   * COMPONENTS
   *----------------------------------------------------------------------------*/
  components: {
    NotesFloat,
    ColorSelector
  },

  /*------------------------------------------------------------------------------
   * WATCH
   *----------------------------------------------------------------------------*/
  watch: {
    'section': {
      handler(section) {
        if (section) {
          this.getBlockData()
        }
      },
      immediate: true
    }
  },

  /*------------------------------------------------------------------------------
   * METHODS
   *----------------------------------------------------------------------------*/
  methods: {
    ...mapActions('blocks', [
      'getBlock'
    ]),

    async getFileData() {
      this.data = this.$store.getters['blocks/data'](this.section.block)

      if (this.data) {
        let index = _camelCase(`${this.section.orientation} ${this.section.color} img`)
        let jpegIndex = _camelCase(`${this.section.orientation} ${this.section.color} img jpeg`)
        this.file = this.data[index]
        this.jpeg = this.data[jpegIndex]
        this.type = index

         var storage  = firebase.storage()
  
        await storage.ref(`blocks/${this.jpeg}`)
        .getDownloadURL()
        .then(url => {
          this.jpeg = url
        })

        if (this.$store.state.sections.task !== 'replace') {
          // SET SHORTCODES
          let shortcodeData = Object.assign({}, this.$shortcodes(this.data, this.section))
          let cssData = Object.assign({}, this.$cssCodes(this.data, this.section))

          // IF CUSTOM HEADER OR FOOTER
          if ((this.$store.state.page.selected && this.$store.state.page.selected.header) == 'custom' && this.section.type == 'header') {
            shortcodeData.type = 'customHeader'
            cssData.type = 'customHeader'
          } 
          
          if ((this.$store.state.page.selected && this.$store.state.page.selected.footer) == 'custom' && this.section.type == 'footer') {
            shortcodeData.type = 'customFooter'
            cssData.type = 'customFooter'
          }

          if (['header', 'footer'].includes(this.section.type)) {
            this.$store.dispatch('page/setCanvas', {
              type: this.section.type,
              file: this.file,
              jpeg: this.jpeg,
              order: 0,
              section: this.section.id,
            })
          }
          else {
            this.$store.dispatch('page/setCanvas', {
              type: 'body',
              file: this.file,
              jpeg: this.jpeg,
              order: this.section.order,
              section: this.section.id,
            })
          }

          this.$store.commit('sections/setShortcodes', shortcodeData)
          this.$store.commit('sections/setCssCodes', cssData)
        }
      }
    },

    appendSection(section) {
      let data = {
        action: 'append',
        section
      }

      this.$store.commit('sections/setActionType', data)
      this.$emit('select')
    },
    
    prependSection(section) {
      let data = {
        action: 'prepend',
        section
      }

      this.$store.commit('sections/setActionType', data)
      this.$emit('select')
    },

    duplicateSection(section) {
      this.$store.dispatch('sections/duplicate', section)
    },

    showPopup() {
      console.log('showpopup')
    },

    getBlockData() {
      Promise.all([this.getBlock(this.section.block)])
      .then(() => this.getFileData())
    }
  },
}
</script>

<style lang="scss">
.section-overlay-body .v-overlay__content {
  gap: 20%;
  height: 100%;
  padding-top: 15px;
  align-items: center;
  padding-bottom: 15px;
  flex-direction: column;
  width: 100% !important;
  justify-content: center;
  display: flex !important;
}
</style>