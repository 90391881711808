<template>
  <div>
    <preloader v-if="status.gettingCategories" message="Please wait..." />

    <v-card-text 
      v-if="refined.length && !status.gettingCategories" 
      :style="{ height: '200px', overflowY: 'scroll' }"
    >
      <v-checkbox
        v-if="!status.gettingCategories"
        v-model="showAll"
        label="All"
        hide-details
        dense
      ></v-checkbox>

      <v-checkbox
        v-model="selectedCategories"
        v-for="cat in refined"
        :key="cat.id"
        :class="{ 'grey lighten-4' : category.id == cat.id }"
        :label="cat.name"
        :value="cat.id"
        hide-details
        checked
        dense
      >
        <template v-if="!nonEditable" v-slot:append>
          <v-menu offset-y left>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" icon x-small>
                <v-icon x-small>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list dense>
              <v-list-item
                @click="editCategory(cat)"
                dense
              >
                <v-list-item-icon class="mr-2">
                  <v-icon small>mdi-pencil</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Edit</v-list-item-title>
              </v-list-item>
              <v-list-item
                @click="showDeleteDialog(cat)"
                dense
              >
                <v-list-item-icon class="mr-2">
                  <v-icon color="error" small>mdi-delete</v-icon>
                </v-list-item-icon>
                <v-list-item-title class="error--text">Delete</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-checkbox>
    </v-card-text>
    <v-card-actions v-if="!nonEditable">
      <v-form @submit.prevent="validateForm()" class="flex-grow-1 mt-3" ref="form">
        <v-text-field
          v-model="category.name"
          :rules="[rules.required]"
          placeholder="Category Name"
          hide-details
          outlined
          dense
        >
          <template v-slot:append>
            <v-btn @click="resetData()" v-if="category.id" class="mr-1" icon small>
              <v-icon small>mdi-close</v-icon>
            </v-btn>

            <v-btn 
              @click="validateForm()" 
              :loading="status.addingCategory"
              color="accent gradient" 
              height="25" 
              width="25" 
              depressed 
              small
              fab 
            >
              <v-icon small>mdi-plus</v-icon>
            </v-btn>
          </template>
        </v-text-field>
      </v-form>
    </v-card-actions>

    <!-- CONFIRM DELETE CATEGORY -->
    <confirm-delete
      :show="deleteDialog"
      :message="deleteMessage"
      :deleting="status.deletingCategory"
      @confirmed="deleteConfirmed()"
      @cancel="hideDeleteDialog()"
    />
  </div>
</template>

<script>
import rules from '@/rules'
import { mapState, mapActions } from 'vuex'
import { orderBy } from 'lodash'

export default {
  props: {
    nonEditable: Boolean
  },

  /*------------------------------------------------------------------------------
   * DATA
   *----------------------------------------------------------------------------*/
  data() {
    return {
      rules,
      category: {},
      toDelete: null,
      deleteDialog: false,
      deleteMessage: null,
      selectedCategories: [],
    }
  },

  /*------------------------------------------------------------------------------
   * COMPUTED
   *----------------------------------------------------------------------------*/
  computed: {
    ...mapState({
      categories: (state) => state.pageTemplates.categories,
      status: (state) => state.pageTemplates.status,
    }),

    refined: function () {
      return orderBy(this.categories, 'name', 'asc')
    },

    showAll: {
      get: function () {
        return this.categories.length == this.selectedCategories.length
      },

      set: function (val) {
        if (val) {
          let categories = []
          
          this.categories.forEach(category => {
            categories.push(category.id)
          })

          this.selectedCategories = categories
        }
        else {
          this.selectedCategories = []
        }
      }
    }
  },

  /*------------------------------------------------------------------------------
   * WATCH
   *----------------------------------------------------------------------------*/
  watch: {
    'categories': function (categories) {
      if (categories.length) {
        this.selectedCategories = []

        categories.forEach(category => {
          this.selectedCategories.push(category.id)
        })
      }
    },

    'selectedCategories': function (value) {
      this.$emit('update:categories', value)
    }
  },

  /*------------------------------------------------------------------------------
   * METHODS
   *----------------------------------------------------------------------------*/
  methods: {
    ...mapActions('pageTemplates', [
      'addCategory',
      'getCategories',
      'deleteCategory'
    ]),

    validateForm() {
      if (this.$refs.form.validate()) {
        Promise.all([
          this.addCategory(this.category)
        ])
        .then(() => {
          this.category = {}
        })
      }
    },

    showDeleteDialog(category) {
      this.toDelete = category
      this.deleteMessage = `Delete category ${category.name}?`
      this.deleteDialog = true
    },
    
    hideDeleteDialog() {
      this.toDelete = null
      this.deleteMessage = null
      this.deleteDialog = false
    },

    deleteConfirmed() {
      Promise.all([
        this.deleteCategory(this.toDelete)
      ])
      .then(() => {
        this.hideDeleteDialog()
      })
    },

    editCategory(category) {
      this.category = Object.assign({}, category)
    },

    selectAll() {
      this.categories.forEach(category => {
        this.selectedCategories.push(category.id)
      })
    },

    resetData() {
      this.category = {}
    },
  },

  /*------------------------------------------------------------------------------
   * MOUNTED
   *----------------------------------------------------------------------------*/
  mounted() {
    if (!this.categories.length) this.getCategories()
    if (!this.selectedCategories.length) this.selectAll()
  }
}
</script>