var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{attrs:{"tile":"","flat":""}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.$store.state.preview),expression:"!$store.state.preview"}],staticClass:"page-floating-icons"},[_c('notes-float',{attrs:{"section":_vm.section}})],1),_c('v-fade-transition',[(_vm.file)?_c('BlockImg',{style:(_vm.$store.state.sections.selected && 
              _vm.$store.state.sections.selected.id == _vm.section.id ? 'z-index: 7' : ''),attrs:{"data":_vm.data,"type":_vm.type},on:{"popup":function($event){return _vm.showPopup()}}}):_vm._e()],1),(!_vm.nonEditable)?_c('v-fade-transition',[(hover && !_vm.$store.state.preview)?_c('v-overlay',{staticClass:"section-overlay-body",attrs:{"z-index":"2","absolute":""}},[(_vm.section.type == 'body')?_c('v-card',{staticClass:"d-flex gap-7 pa-1",attrs:{"color":"accent","rounded":"pill"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"black","x-small":"","top":"","fab":""},on:{"click":function($event){return _vm.prependSection(_vm.section)}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-plus-thick")])],1)]}}],null,true)},[_c('span',{staticClass:"caption"},[_vm._v("Add A Panel Above")])])],1):_vm._e(),_c('div',{staticClass:"align-self-start",style:({ position: 'absolute', left: '10px', bottom: '10px' })},[(_vm.section)?_c('color-selector',{attrs:{"section":_vm.section}}):_vm._e()],1),_c('v-card',{staticClass:"d-flex gap-7 pa-1",attrs:{"color":"accent","rounded":"pill"}},[(
                  (_vm.section.type == 'header' && _vm.$store.state.page.selected && _vm.$store.state.page.selected.header !== 'custom') 
                  || (_vm.section.type == 'footer' && _vm.$store.state.page.selected && _vm.$store.state.page.selected.footer !== 'custom') 
                  || _vm.section.type == 'body')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"black","depressed":"","x-small":"","fab":""},on:{"click":function($event){return _vm.$emit('replace', _vm.section)}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-file-replace-outline")])],1)]}}],null,true)},[_c('span',{staticClass:"caption"},[_vm._v("Switch Panel")])]):_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
return [(_vm.section.type == 'body')?_c('v-btn',_vm._g({attrs:{"color":"black","depressed":"","x-small":"","fab":""},on:{"click":function($event){return _vm.duplicateSection(_vm.section)}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-content-copy")])],1):_vm._e()]}}],null,true)},[_c('span',{staticClass:"caption"},[_vm._v("Duplicate Section")])]),(_vm.$store.state.user.user && !_vm.isPageTemplate && !_vm.project.template)?[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"black","depressed":"","x-small":"","fab":""},on:{"click":function($event){return _vm.$store.dispatch('sections/select', {section: _vm.section, view: 'notes'})}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-book-open-outline")])],1)]}}],null,true)},[_c('span',{staticClass:"caption"},[_vm._v("Notes")])]),(_vm.$store.state.user.user && (_vm.$store.getters['user/isAdmin'] || _vm.$store.getters['user/isEmployee']))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"black","depressed":"","x-small":"","fab":""},on:{"click":function($event){return _vm.$store.dispatch('sections/select', {section: _vm.section, view: 'attachments'})}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-paperclip")])],1)]}}],null,true)},[_c('span',{staticClass:"caption"},[_vm._v("Attachments")])]):_vm._e(),(_vm.$store.state.user.user && (_vm.$store.getters['user/isAdmin'] || _vm.$store.getters['user/isEmployee'] || _vm.$store.getters['user/isClient']))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"black","depressed":"","x-small":"","fab":""},on:{"click":function($event){return _vm.$store.dispatch('sections/select', {section: _vm.section, view: 'copy'})}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-file-document-outline")])],1)]}}],null,true)},[_c('span',{staticClass:"caption"},[_vm._v("Content Copy")])]):_vm._e()]:_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"black","depressed":"","x-small":"","fab":""},on:{"click":function($event){return _vm.$emit('delete', _vm.section)}}},on),[_c('v-icon',{attrs:{"color":"red","small":""}},[_vm._v("mdi-trash-can-outline")])],1)]}}],null,true)},[_c('span',{staticClass:"caption"},[_vm._v("Delete")])])],2),(_vm.section.type == 'body')?_c('v-card',{staticClass:"d-flex gap-7 pa-1",attrs:{"color":"accent","rounded":"pill"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"black","x-small":"","top":"","fab":""},on:{"click":function($event){return _vm.appendSection(_vm.section)}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-plus-thick")])],1)]}}],null,true)},[_c('span',{staticClass:"caption"},[_vm._v("Add A Panel Below")])])],1):_vm._e()],1):_vm._e()],1):_vm._e()],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }