<template>
  <div class="d-flex gap-10">
    <v-btn-toggle 
      v-model="selectedOrientation" 
      class="black" 
      mandatory
    >
      <v-btn
        v-for="(orientation, i) in $store.getters['blocks/data'](section.block).orientations"
        :key="i"
        :value="orientation"
        @click="updateSection({ orientation })"
        x-small
        icon
      >
        <v-icon x-small>mdi-format-align-{{ orientation }}</v-icon>
      </v-btn>
    </v-btn-toggle>

    <v-btn-toggle 
      v-model="selectedColor" 
      class="black" 
      mandatory
    >
      <v-btn
        v-for="(color, i) in refined"
        :key="i"
        :value="color.name"
        @click="updateSection({ color: color.name })"
        x-small icon
      >
        <v-icon x-small :color="color.color">mdi-brightness-1</v-icon>
      </v-btn>
    </v-btn-toggle>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { camelCase, merge } from 'lodash'

export default {
  /*------------------------------------------------------------------------------
   * PROPS
   *----------------------------------------------------------------------------*/  
  props: {
    section: Object
  },

  /*------------------------------------------------------------------------------
   * DATA
   *----------------------------------------------------------------------------*/
  data() {
    return {
      selectedColor: null,
      selectedOrientation: null,
      colors: [
        { name: 'dark', color: 'primary' },
        { name: 'grey', color: 'grey' },
        { name: 'light', color: 'white' },
      ],
      orientations: ['left', 'center', 'right']
    }
  },
  
  /*------------------------------------------------------------------------------
   * COMPUTED
   *----------------------------------------------------------------------------*/
  computed: {
    refined: function () {
      let block = this.$store.getters['blocks/data'](this.section.block)

      return this.colors.filter(color => {
        let hasColor = false

        block.orientations.forEach(o => {
          hasColor = !!block[camelCase(`${o}-${color.name}-img`)]
        })

        return hasColor
      })
    }
  },

  /*------------------------------------------------------------------------------
   * METHODS
   *----------------------------------------------------------------------------*/
  methods: {
    ...mapActions('blocks', [
      'getBlock'
    ]),
    
    ...mapActions('sections', [
      'updateSectionData'
    ]),

    updateSection(data) {
      let newData = Object.assign({}, this.section)
      newData = merge(newData, data)
      this.updateSectionData(newData)
    }
  },

  /*------------------------------------------------------------------------------
   * MOUNTED
   *----------------------------------------------------------------------------*/
  mounted() {
    if (this.section) {
      this.getBlock(this.section.block)
      this.selectedColor = this.section.color
    }
  }
}
</script>