<template>
  <v-card v-if="template" class="flex-grow-w" flat tile>
    <div v-if="!previewOnly" class="d-flex align-center mb-3">
      <v-sheet width="30%">
        <v-text-field
          v-model="template.name"
          @input="templateUpdated($event)"
          :rules="[rules.required]"
          hide-details
          outlined
          dense
        ></v-text-field>
      </v-sheet>

      <v-spacer></v-spacer>

      <div class="d-flex gap-10">
        <v-btn :to="{ name: 'Project', params: { id: template.id }, query: { type: 'page-template' } }" color="primary" class="subtle" icon small>
          <v-icon>mdi-palette-advanced</v-icon>
        </v-btn>

        <v-btn @click="showDeleteDialog()" color="primary" class="subtle" icon small>
          <v-icon>mdi-delete</v-icon>
        </v-btn>
        
        <v-switch
          v-model="template.visible"
          @change="templateUpdated(true)"
          :true-value="true"
          :false-value="false"
          hide-details
          class="ma-0"
          inset
          dense
        ></v-switch>
        
      </div>
    </div>

    <div v-if="!previewOnly" class="mb-3">
      <v-sheet>
        <v-autocomplete
          v-model="template.categories"
          @blur="templateUpdated($event)"
          :items="orderBy(categories, 'name', 'asc')"
          item-text="name"
          item-value="id"
          deletable-chips
          hide-details
          small-chips
          multiple
          outlined
          chips
          dense
        ></v-autocomplete>
      </v-sheet>
    </div>

    <v-card v-if="refined.length" outlined tile>
      <page-section
        v-for="section in refined"
        :key="section.id"
        :section="section"
        nonEditable
      ></page-section>
    </v-card>

    <!-- CONFIRM DELETE -->
    <confirm-delete
      :show="deleteDialog"
      :deleting="status.deleting"
      message="Are you sure you want to delete the page template?"
      @cancel="hideDeleteDialog()"
      @confirmed="deteConfirmed()"
    />
  </v-card>
</template>

<script>
import PageSection from '@/components/PageSection'
import { mapState, mapActions } from 'vuex'
import { debounce, orderBy } from 'lodash'
import rules from '@/rules'

export default {
  /*------------------------------------------------------------------------------
   * DATA
   *----------------------------------------------------------------------------*/
  data() {
    return {
      rules,
      orderBy,
      sections: [],
      deleteDialog: false,
    }
  },

  /*------------------------------------------------------------------------------
   * COMPUTED
   *----------------------------------------------------------------------------*/
  computed: {
    ...mapState({
      status: (state) => state.pageTemplates.status,
      categories: (state) => state.pageTemplates.categories,
    }),

    refined: function () {
      return orderBy(this.sections, 'order', 'asc')
    }
  },

  /*------------------------------------------------------------------------------
   * PROPERTIES
   *----------------------------------------------------------------------------*/
  props: {
    template: {
      type: Object,
      required: true
    },

    previewOnly: Boolean,
  },

  /*------------------------------------------------------------------------------
   * WATCH
   *----------------------------------------------------------------------------*/
  watch: {
    'template': function () {
      this.getSections()  
    }
  },

  /*------------------------------------------------------------------------------
   * COMPONENTS
   *----------------------------------------------------------------------------*/
  components: {
    PageSection
  },

  /*------------------------------------------------------------------------------
   * METHODS
   *----------------------------------------------------------------------------*/
  methods: {
    ...mapActions('pageTemplates', [
      'deleteTemplate',
      'updateTemplate'
    ]),

    getSections() {
      this.sections = []

      if (this.template) {
        this.template.ref
        .collection('sections')
        .get()
        .then((snapshot) => {
          if (snapshot.size) {
            snapshot.forEach(doc => {
              let data = doc.data()
              data.id = doc.id
              data.ref = doc.ref
              this.sections.push(data)
            })
          }
        })
        .catch(error => {
          console.log(error.message)
        })
      }
    },

    showDeleteDialog() {
      this.deleteDialog = true
    },
    
    hideDeleteDialog() {
      this.deleteDialog = false
    },

    deteConfirmed() {
      Promise.all([
        this.deleteTemplate(this.template)
      ])
      .then(() => {
        this.hideDeleteDialog()
        this.$emit('deleted')
      })
    },

    templateUpdated: debounce(function (value) {
      if (value) {
        this.updateTemplate(this.template)
      }
    }, 1000)
  },

  /*------------------------------------------------------------------------------
   * MOUNTED
   *----------------------------------------------------------------------------*/
  mounted() {
    this.getSections()
  }
}
</script>